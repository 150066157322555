import { X_MS_VECTOR_ID } from '../../components/Constants';
import { AxiosCustomConfig } from '../httpClient/HttpClient';

/* Add x-ms-vector-id header to request by getting MUID cookie
 */
const XMSVectorIdInterceptor =  (config: AxiosCustomConfig) => {
    if (config.xMSVectorId) 
        config.headers[X_MS_VECTOR_ID] = config.xMSVectorId;
    return config;
};
export default XMSVectorIdInterceptor;