import {CustomWindow} from '../window/CustomWindow';
// cast global var window with CustomWindow
declare let window: CustomWindow;

/* A utility class to retrive authentication token
 */
export class AuthenticationTokenHandler {
    static async getCompactTicket(): Promise<string> {
        if (!(window.msauth && window.msauth.AuthTicket)) { return Promise.resolve(null);}
        return Promise.resolve(window.msauth.AuthTicket);
    }

    static async isUserSignedIn(): Promise<boolean> {
        if (!(window.msauth && window.msauth.AuthTicket)) { return Promise.resolve(false);}
        return Promise.resolve(window.msauth.AuthTicket !== null);

    }

    static async getUserName(): Promise<string> {
        if (!(window.msauth && window.msauth.FirstName)) { return Promise.resolve(null);}
        return Promise.resolve(window.msauth.FirstName);
    }

    static async getUserType(): Promise<string> {
        if (!(window.msauth && window.msauth.Type)) { return Promise.resolve(null);}
        return Promise.resolve(window.msauth.Type);
    }

    static async getAuthTicketEx(): Promise<string> {
        if (!(window.msauth && window.msauth.AuthTicketEx)) { return Promise.resolve(null);}
        return Promise.resolve(window.msauth.AuthTicketEx);
    }
}
