import { AxiosCustomConfig } from '../httpClient/HttpClient';
import { CookieHandler } from '../handlers/CookieHandler';
import { MUIDCookieName } from '../../components/Constants';

/* Add x-authorization-muid header to request by getting MUID cookie
 */
const XAuthMUIDInterceptor =  (config: AxiosCustomConfig) => {
    if(config.MUIDHeader != null) {
        let authorizationMUID =  CookieHandler.getCookie(MUIDCookieName);
        if (authorizationMUID != "") {
            config.headers[config.MUIDHeader] = authorizationMUID;
        }
    }
    return config;
};
export default XAuthMUIDInterceptor;