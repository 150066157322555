import { AxiosCustomConfig } from '../httpClient/HttpClient';
import { AuthenticationTokenHandler } from '../authentication/AuthenticationTokenHandler';

/* Add the auth header to the request. In case the request is passed with
 custom header name along with the prefix, it will be set. 
 */
const CompactTicketHeaderInterceptor = async (config: AxiosCustomConfig) => {
    const compactTicket = await AuthenticationTokenHandler.getCompactTicket();
    const authTicketEx = await AuthenticationTokenHandler.getAuthTicketEx();
    const userType = await AuthenticationTokenHandler.getUserType();

    //Set the authorization header along with the prefix if passed in the config
    if (compactTicket != null) {
        if (config.CompactHeaderName != null
            && config.CompactHeaderPrefix != null) {            
            if(userType != null && userType.toLowerCase() == "aad" && config.AadHeaderPrefix && authTicketEx != null){
                config.headers[config.CompactHeaderName] = config.AadHeaderPrefix + authTicketEx;
            }
            else{
                config.headers[config.CompactHeaderName] = config.CompactHeaderPrefix + compactTicket;
            }
        }
        //Set the compact ticket header if the custom header is not passed
        else {
            config.headers['compactTicket'] = compactTicket;
        }
    };
    return config;
}
export default CompactTicketHeaderInterceptor;
