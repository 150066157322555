//The below function can be used to generate a guid in this particular format - 40 digit character
//length with '-' dashes included. you can also refere to generateCookie.js file inorder to 
//get a different format one with 36 character length and no dashes.
export const createGuid = () => {
    const guidPattern = "xxxxxxxx-xxxx-4xxx-Rxxx-xxxxxxxxxxxx";
    function randomHexDigit() {
        return Math.floor(getRandomBetweenZeroAndOne() * 16).toString(16);
    }
    const result = guidPattern.replace(/x/g, randomHexDigit);
    // update remaining 2 bits of first digit of the clock_seq_hi_and_reserved:
    return result.replace("R", (8 | Math.floor(getRandomBetweenZeroAndOne() * 3)).toString(16));
}

export const getRandomBetweenZeroAndOne = () => {
    const crypto: Crypto = window.crypto || (<any>window).msCrypto;
    if (crypto && crypto.getRandomValues) {
        return crypto.getRandomValues(new Uint32Array(1))[0] / Math.pow(16, 8);
    }
    return Math.random();
}