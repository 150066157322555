import { AxiosCustomConfig } from '../httpClient/HttpClient';
import { MsCVHandler } from '../cv/MsCVHandler';
import uniqid from 'uniqid';

/* Add MsCV header to request after incrementing it so that we send unique CV value in the request
 */
const MsCVHeaderInterceptor =  (config: AxiosCustomConfig) => {
    let msCv = MsCVHandler.getMsCV();
    if (config.cv && config.xMSVectorId) {
        // For the add to cart use case, always increment the CV for all the calls including retries
        if (msCv != null) {
            config.headers['MS-CV'] = msCv.increment();
        } else {
            config.headers['MS-CV'] = config.cv;
        }
    } else {
        //Correlation Vector also called as CV is used for tracing request across services and downstream systems 
        if (msCv != null) {
            config.headers['MS-CV'] = msCv.increment();
        } else {
            config.headers['MS-CV'] = uniqid('casc');
        }
    }
    return config;
};
export default MsCVHeaderInterceptor;