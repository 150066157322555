import { CART_PERF, X_AEM_LOAD_TEST } from '../../components/Constants';
import { AxiosCustomConfig } from '../httpClient/HttpClient';

const XAEMLoadTestInterceptor = (config: AxiosCustomConfig) => {
   
    if(typeof window !== 'undefined' && window.location.href.includes(CART_PERF+'=true')){

        config.headers[X_AEM_LOAD_TEST] = 'true';
    }
   
    return config;
}

export default XAEMLoadTestInterceptor;