import { CustomWindow,IncrementCV } from '../window/CustomWindow';
 
// cast global var window with CustomWindow
declare let window: CustomWindow;
 
/* A utility class to retrive MSCV. We shall increment and set the value in the request header if the 
    CV is available
 */
export class MsCVHandler {     
    static  getMsCV(): IncrementCV {
        return window.telemetry && window.telemetry.correlationVectorPlugin ? window.telemetry.correlationVectorPlugin.getCv() : null;
    }  
}
