import { createGuid } from '../utils/commonUtils';
import { CookieHandler } from '../handlers/CookieHandler';
import { MS0CookieName } from '../../components/Constants';

const RiskSessionIdInterceptor = (config: any) => {
    if(config.riskIdAppend)
    {
        const riskId = CookieHandler.getCookie(MS0CookieName) || createGuid();
        if (typeof config.data == 'string') {
            let payload = JSON.parse(config.data);
            payload.riskSessionId = riskId;
            config.data = JSON.stringify(payload);
        } else if (typeof config.data == 'object') {
            config.data['riskSessionId'] = riskId;
        }
    }
    return config;
}

export default RiskSessionIdInterceptor;
