export const LOAD_CART_URL_PERF = 'https://cart.perf.store-web.dynamics.com/v1.0/cart/loadCart';
export const LOAD_CART_URL_PPE = 'https://cart.ppe.store-web.dynamics.com/v1.0/cart/loadCart';
export const LOAD_CART_URL_INT = 'https://cart.staging.store-web.dynamics.com/v1.0/cart/loadCart';
export const LOAD_CART_URL = 'https://cart.production.store-web.dynamics.com/v1.0/cart/loadCart';
export const REDIRECT_CART_URL = 'https://www.microsoft.com/$locale/store/buy';
export const CART_ERROR_SERVICE = 'https://cem.services.microsoft.com/v1/$locale/errors/$code-$reason/Storefront';
export const CONFIGURE_QUICKHREFS_URL = 'https://$hostname/$locale$sandboxPath/store/configure/$productTitle/$bigId';
export const CONFIGURE_DEV_URL = 'https://wwwdev.microsoft.com/$locale/store/configure/$productTitle/$bigId?crosssellid=&selectedColor=$color';
export const CONFIGURE_URL = 'https://$hostName/$locale/store/configure/$productTitle/$bigId?crosssellid=&selectedColor=$color';
export const CONFIGURE_SANDBOX_URL = 'https://$hostname/$locale/windowsany/$user/store/configure/$productTitle/$bigId?crosssellid=&selectedColor=$color';
export const BUILD_URL = 'https://www.microsoft.com/$locale/store/build/$productTitle/$bigId?cid=&preview=&previewModes=';
export const BUILD_QUICK_HREFS_URL = 'https://www.microsoft.com/$locale/store/build/$productTitle/$bigId';
export const MANAGE_URL = 'https://account.microsoft.com/services/';
export const REDIRECT_INTERSTITIAL_URL='https://www.microsoft.com/$locale/store/buy';
export const PREORDER = 'PREORDER';
export const CHECKYOURELIGIBILITY = 'Check your eligibility';
export const MUIDCookieName = 'cartMuid';
export const MS0CookieName = 'MS0';
export const AUTH_HEADER_NAME = 'Authorization';
export const COMPACT_TICKET_HEADER_NAME = 'compactTicket';
export const TENTED_PAGE_FLAG_HEADER_NAME = 'x-ms-tented';
export const AUTH_HEADER_PREFIX = 'WLID1.0=t=';
export const AUTH_AAD_HEADER_PREFIX = 'Bearer ';
export const X_AUTH_MUID_HEADER = 'x-authorization-muid';
export const X_AEM_LOAD_TEST = 'x-aem-load-test';
export const UcsSidCookieName = 'ucs_sid';
export const X_MS_UCS_SID = 'x-ms-ucs-sid';
export const MUID_HEADER_NAME = 'MUID';
export const CART_TYPE = 'consumer'; //TODO: Awaiting response for this value
export const APP_ID = 'AemWeb';
export const CATALOG_CLIENT_TYPE = 'storeWeb'; // StoreWeb is the value used by all the store web pages.
export const CLIENT = 'UniversalWebStore.Cart'; // UniversalWebStore.Cart is the one used by all the store web pages.
export const DEVICE_TYPE_PC = 'Pc'; // Pc is default value when deviceFamily is null.
export const DEVICE_FAMILY = 'devicefamily';
export const DEVICE_TYPE_UNKNOWN = 'Unknown';
export const PCID = 'pcid';
export const OCID = 'ocid';
export const CID = 'cid';
export const PROD = 'prod';
export const PPE = 'ppe';
export const PERF = 'perf';
export const INT = 'staging';
export const CART_DASH = 'cart-';
export const X_MS_VECTOR_ID = 'X-MS-Vector-Id';
export const X_MS_CLIENT_TYPE = 'X-MS-Client-Type';
export const X_MS_MARKET = 'X-MS-Market';
const DEVICE_TYPE_JSON = {
    'windows.desktop':'Pc',
    'windows.mobile':'Mobile',
    'windows.xbox':'Xbox',
    'windows.holographic':'Hololens',
    'windows.team':'Surface',
    'mac':'Mac',
    'ios':'Ios',
    'android':'Android',
    'windows.windowsPhone8x':'Unknown',
    'windows.windowsPhone7x':'Unknown',
    'windows.windows8x':'Unknown',
    'windows.universal':'Unknown',
}
export const DEVICE_TYPE_MAP = new Map(Object.entries(DEVICE_TYPE_JSON));
export const GET_REVIEW_STATUS = 'getReviewStatus'
export const ENABLE_PRE_RELEASE_CHECK = "enablePreReleaseCheck"
export const SKU_LENGTH_CHECK_VALUE = 4;
export const ACTIVE_TAB_FLAG = 'activetab';
export const INVISIBLE_FLAG = 'Invisibiliarevelare';
export const CART_PPE = 'cartPPE';
export const CART_PERF = 'cartPERF';
export const CART_INT = 'cartINT';
export const AEM_REDIRECT_FLAG = 'aemredirect';
export const PUSH_TO_INSTALL_MODAL_ID = 'pti-modal';
export const DEFAULT_LOCALE = 'en-us';
export const BUYBOX_JSLL_COMPNAME = 'Buy Box';
export const PROD_URL_ROOT = 'https://www.microsoft.com';
export const ADD_TO_CART_TAG = '[AddToCart]';
export const BUY_NOW_PPE = 'buyNowPPE';
export const CART_QSP_UCS = 'enableucs';

// i18n strings
export const CV_SUPPORT_TEXT1 = 'If you need any additional assistance, please contact Microsoft Store Support at {0}';
export const CV_SUPPORT_TEXT2 = 'Please save the following error code(s) so that our support advocates may be able to better assist you:';
export const VIEW_CART = 'View cart';
export const CONTINUE_SHOPPING = 'Continue shopping';
export const CLOSE_DIALOG_WINDOW = 'Close dialog window';
export const CART_SUPPORT_URL = 'https://aka.ms/storecs';

// Update following prototype business URLs with final URLs when available 
export const BUSINESS_SIGN_UP_URL = "https://prv.signup.microsoft.com/CreateAccount"
export const CASCADE_BUSINESS_SIGN_IN_URL = "https://wwwdev.microsoft.com/cascadeauth/v1/store-business/account/signinto";

// Manage your account tab on authenticated home page
export const MICROSOFT_ADMIN_CENTER = 'https://aka.ms/mac-redirect';
export const MANAGE_RECENT_ORDERS = 'https://aka.ms/mac-recentorders';
export const MANAGE_ADDRESS = 'https://aka.ms/mac-manageaddress';
export const MANAGE_PAYMENT_OPTIONS = 'https://aka.ms/mac-payment';
export const MANAGE_INFO = 'https://aka.ms/mac-manageusers';
export const PRIVACY_STATEMENT_PRIVACY_AND_SECURITY = 'https://aka.ms/mac-privacystatement';
export const START_SHOPPING = 'https://www.microsoft.com/{0}/store/b/surfaceforbusiness?icid=SMB_quicklink_businessdevices';
export const CANNOT_FIND_ORDER = 'https://aka.ms/mac-recentorders';
export const IMAGE_PLACEHOLDER = "https://s7d2.scene7.com/is/image/microsoftcorp/mwf-placeholder";

// Cart Payload
export const BILLING_ACCOUNT_ID_COOKIE_NAME = "BillingAcctId";

// Business Cart redirect url
export const REDIRECT_BUSINESS_CART_URL = 'https://wwwppe.microsoft.com/$locale/store/business/cart';

export const USER_SIGNED_IN_TYPE_BUSINESS = 'aad';
export const ORDERING_EXPERIENCE_COMMERCIAL = 'Commercial';
export const ORDERING_EXPERIENCE_CONSUMER = 'Consumer';
export const CATALOG_ID_COMMERCIAL = 4;
export const CATALOG_ID_CONSUMER = 1;
export const USER_SIGNED_IN_TYPE_MSA = 'msa';
export const CONSUMER_ONLY_PRODUCT_REDIRECT_URL = '/$locale/store/business-interstitial-error?status=consumeronlyproduct';
export enum SourceCatalog {
    Cat1 = 'Cat1',
    Cat4 = 'Cat4'
};

// loadCart error codes
export const FAILED_TO_ADD_ITEM = 'FailedToAddItem';
export const UPDATED_ITEM_TO_ADD = 'UpdatedItemToAdd';
export const Failed_TO_UPDATED_LINE_ITEM = 'FailedToUpdateLineItem';
export const PUR = 'PUR';

// loadCart error reasons
export const MAX_OVERALL_CART_QUANTITY_EXCEEDED = 'MaxOverallCartQuantityExceeded';
export const MAX_LINE_ITEM_QUANTITY_EXCEEDED = 'MaxLineItemQuantityExceeded';
export const QUANTITY_LIMIT_EXCEEDED = 'QuantityLimitExceeded';

// loadCart error loc keys
export const CART_LIMIT_ERROR = 'CART_LIMIT_ERROR';
export const CART_LIMIT_DESCRIPTION = 'CART_LIMIT_DESCRIPTION';
export const FULL_CART_ERROR = 'FULL_CART_ERROR';
export const FULL_CART_DESCRIPTION = 'FULL_CART_DESCRIPTION';
export const MAX_ITEM_ERROR = 'MAX_ITEM_ERROR';
export const MAX_ITEM_DESCRIPTION = 'MAX_ITEM_DESCRIPTION';
export const MAX_QUANTITY_DESCRIPTION = 'MAX_QUANTITY_DESCRIPTION';
export const UPDATE_QUANTITY_ERROR = 'UPDATE_QUANTITY_ERROR';
export const UPDATE_QUANTITY_DESCRIPTION = 'UPDATE_QUANTITY_DESCRIPTION';
export const CART_ITEM_ERROR = 'CART_ITEM_ERROR';
export const CART_ITEM_DESCRIPTION = 'CART_ITEM_DESCRIPTION';
export const CART_DEFAULT_ERROR = 'SOMETHING_WENT_WRONG';

// loadCart error messages
export const CART_LIMIT_SHORT_MESSAGE = "You've reached the limit of items you can add to cart.";
export const CART_LIMIT_LONG_MESSAGE = 'To add items to your cart, first remove existing items, reduce quantities, or save items for later.';
export const FULL_CART_SHORT_MESSAGE = 'Your cart is full.';
export const FULL_CART_LONG_MESSAGE = 'Unable to add line item to cart, cart quantity is at its max.';
export const MAX_ITEM_SHORT_MESSAGE = "You've reached the maximum number of items you can add to your cart.";
export const MAX_ITEM_LONG_MESSAGE = "Could not update quantity, You've reached the maximum number of items you can add to your cart.";
export const MAX_QUANTITY_LONG_MESSAGE = 'You have reached the max quantity for your product.';
export const UPDATE_QUANTITY_SHORT_MESSAGE = 'Max product quantity reached';
export const UPDATE_QUANTITY_LONG_MESSAGE = 'Could not update quantity, the requested quantity is greater than the maximum allowed.';
export const CART_ITEM_SHORT_MESSAGE = "Can't add this item to your cart";
export const CART_ITEM_LONG_MESSAGE = 'Could not add the requested item as you are already at the limit for this item in your cart';
export const CART_DEFAULT_SHORT_MESSAGE = 'Something went wrong ...';