import axios, {AxiosInstance} from 'axios';
import CompactTicketHeaderInterceptor from '../interceptors/CompactTicketHeaderInterceptor';
import MsCVHeaderInterceptor from '../interceptors/MsCVHeaderInterceptor';
import XAuthMUIDInterceptor from '../interceptors/XAuthMUIDInterceptor';
import RiskSessionIdInterceptor from '../interceptors/RiskSessionIDGeneratorInterceptor';
import FirstNameHeaderInterceptor from '../interceptors/FirstNameHeaderInterceptor';
import TentOptionsHeaderInterceptor from '../interceptors/TentOptionsHeaderInterceptor';
import axiosRetry from 'axios-retry'
import XAEMLoadTestInterceptor from '../interceptors/XAEMLoadTestInterceptor';
import XMSVectorIdInterceptor from '../interceptors/XMSVectorIdInterceptor';
import XMSUcsCookieInterceptor from '../interceptors/XMSUcsCookieInterceptor';


/**
 * Build custom Http client. Client can contain variable parts to use if needed.
 * example:
 * const client = new HttpClientBuilder().addAuthentication().addXAuthMUID().build();
 */
export class HttpClientBuilder {
    static readonly RETRIES_COUNT_DEFAULT = 3;
    static readonly RETRY_TIMEOUT_DEFAULT = 50;

    private readonly _client: AxiosInstance;

    constructor() {
        this._client = axios.create();
    }

    /**
     * add Correlation Vector to the request header
     */
    addCV(): HttpClientBuilder {
        this._client.interceptors.request.use(MsCVHeaderInterceptor);
        return this;
    }

    /**
     * add Authentication tokens avaiable in the request header
     */
    addAuthentication(): HttpClientBuilder {
        this._client.interceptors.request.use(CompactTicketHeaderInterceptor);
        return this;
    }

    /**
     * add MUID cookie avaiable in the request header
     */
    addXAuthMUID(): HttpClientBuilder {
        this._client.interceptors.request.use(XAuthMUIDInterceptor);
        return this;
    }

    /**
     * add riskSessionId to the request header of any API call
     */
    addRiskSessionId(): HttpClientBuilder {
        this._client.interceptors.request.use(RiskSessionIdInterceptor);
        return this;
    }

    /**
     * add First Name to the request header of any API call
     */
    addFirstName(): HttpClientBuilder {
        this._client.interceptors.request.use(FirstNameHeaderInterceptor);
        return this;
    }

    /**
     * add tent options to the request header of any API call
     */
    addTentOptions(): HttpClientBuilder {
        this._client.interceptors.request.use(TentOptionsHeaderInterceptor);
        return this;
    }
    /**
     * add AEM Load test options to the request header of any API call
     */
     addXAEMLoadTest(): HttpClientBuilder {
        this._client.interceptors.request.use(XAEMLoadTestInterceptor);
        return this;
    }

     /**
     * add X-MS-Vector_Id options to the request header of any API call
     */
      addXMSVectorId(): HttpClientBuilder {
        this._client.interceptors.request.use(XMSVectorIdInterceptor);
        return this;
    }
    
    /**
    * add x-ms-ucs-sid to the request header of any API call
    */
     addUcsSid(): HttpClientBuilder {
       this._client.interceptors.request.use(XMSUcsCookieInterceptor);
       return this;
   }
    /**
     * intercepts failed requests (HTTP code 5XX) and retries them whenever possible.
     * @param retryCount The number of times to retry before failing.
     * @param retryTimeout Delay before retry
     */
    addRetry(retryCount:number,retryTimeout:number): HttpClientBuilder {
        const delayFunc = (retryCount: number, error: Error) => retryTimeout;
        axiosRetry(this._client, {
            retries: retryCount,
            retryDelay: delayFunc
        });
        return this;
    }

    build() {
        return this._client;
    }
}