import { X_MS_UCS_SID, UcsSidCookieName } from '../../components/Constants';
import { AxiosCustomConfig } from '../httpClient/HttpClient';
import { CookieHandler } from '../handlers/CookieHandler';

/* Add x-ms-usc_sid header to request if present
 */
const XMSUcsCookieInterceptor =  (config: AxiosCustomConfig) => {
    const cookieValue = CookieHandler.getCookie(UcsSidCookieName);
    if (cookieValue) 
        config.headers[X_MS_UCS_SID] = cookieValue;
    return config;
};
export default XMSUcsCookieInterceptor;