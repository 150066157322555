/* A utility class to retrive cookie
 */
export class CookieHandler {
    static getCookie(cname: string): string {
         let name = cname + "=";
         if (typeof document !== 'undefined') {
          let decodedCookie = decodeURIComponent(document.cookie);
          let ca = decodedCookie.split(';');
          for(let i = 0; i <ca.length; i++) {
              let c = ca[i];
              c = c.trim();
              if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
              }
          }
          return "";
         }

    }
}
