import { HttpClientBuilder } from '../httpClient/HttpClientBuilder';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface UserInfo {
    AuthTicket?: string;
    FirstName?: string;
}

/**
 * User info singelton returns promise from axios HttpClient
 * UserInfoSingleton.getInstance().getUserInfo().then( data  => {console.log(data);})
 */

export class UserInfoSingleton {
    private static _instance: UserInfoSingleton;
    private _isConfigured: boolean;
    private _userInfo: Promise<UserInfo>;

    public getUserInfo(): Promise<UserInfo> {
        return this._userInfo;
    }

    private constructor() {
        const userInfoConfigPlaceHolderElement = document.querySelector<HTMLElement>('.userInfo-config');
        const meControlConfigElement = document.querySelector<HTMLElement>('.meControl-configInfo');
        const isV2Enabled = this.isV2Enabled(userInfoConfigPlaceHolderElement);

        const claimsURL = this.getUrl(userInfoConfigPlaceHolderElement);
        const timeoutInMS = this.getTimeout(userInfoConfigPlaceHolderElement);
        const isMeControlEnable = this.isMeControlEnable(meControlConfigElement);
        const requestConfig: AxiosRequestConfig = {
            timeout: timeoutInMS,
            withCredentials: true
        };

        if (userInfoConfigPlaceHolderElement !== null && claimsURL !== null && isMeControlEnable) {
            this._isConfigured = true;

            //check if v2userinfo is enabled, if it is get the v2userinfourl and perform v2UserInfoCall on response
            if (isV2Enabled) {
                const userInfoV2 = document.querySelector<HTMLElement>('.userInfo-config').getAttribute('data-v2url');
                const requestConfigV2: AxiosRequestConfig = {
                    timeout: timeoutInMS,
                    withCredentials: true,
                    params:{ru: `${location.hostname}${location.pathname}${location.search}`}
                };

                this._userInfo = new HttpClientBuilder().addCV()
                    .addRetry(HttpClientBuilder.RETRIES_COUNT_DEFAULT, HttpClientBuilder.RETRY_TIMEOUT_DEFAULT)
                    .build().get(userInfoV2, requestConfigV2).then((res: AxiosResponse<UserInfo>) => res.data)
                    .catch((error: AxiosError<any>) => {
                        /* In token refresh scenario, browser automatically follows 302 response from RPS userinfo to the RPS signin endpoint.
                            Signin endpoint responds with redirect to login.live for token refresh - which results in a CORS error when request comes
                            from author as author is not on the CDN. Per WC3 documentation, http://www.w3.org/TR/cors/#simple-cross-origin-request , CORS errors are treated as 
                            general network errors with no status code or response.
                        */
                        if(!error.code && !error.response){
                            // Perform page level redirect to login.live, bypassing CORS
                            var signInURL = document.querySelector<HTMLElement>('.meControl-configInfo').getAttribute('data-signinurl');
                            //add location of the current page so user is redirected back to the page they started on
                           location.href = `${signInURL}?ru=${window.location.href}`;
                            return null;
                        }
                    });
            } else {
                //do the regular call to the standard userinfo endpoint
                this._userInfo = new HttpClientBuilder().addCV()
                    .addRetry(HttpClientBuilder.RETRIES_COUNT_DEFAULT, HttpClientBuilder.RETRY_TIMEOUT_DEFAULT)
                    .build().get(claimsURL, requestConfig).then((res: AxiosResponse<UserInfo>) => res.data);
            }
        } else {
            this._isConfigured = false;
            this._userInfo = Promise.resolve(null);
        }
    }

    private getTimeout(userInfoConfigPlaceHolderElement: HTMLElement) {
        if (userInfoConfigPlaceHolderElement == null) return null;
        return parseInt(userInfoConfigPlaceHolderElement.getAttribute('data-userinfotimeoutms'));
    }

    private getUrl(userInfoConfigPlaceHolderElement: HTMLElement) {
        if (userInfoConfigPlaceHolderElement == null) return null;
        return userInfoConfigPlaceHolderElement.getAttribute('data-userinfourl');
    }
    private isV2Enabled(userInfoConfigPlaceHolderElement: HTMLElement) {
        if (userInfoConfigPlaceHolderElement == null) return false;
        return 'true' === userInfoConfigPlaceHolderElement.getAttribute('data-isV2enabled');
    }

    private isMeControlEnable(meControl: HTMLElement) {
        if (meControl == null) return false;
        return 'true' === meControl.getAttribute('data-isenabled');
    }

    /**
     * Pages without universal header may not contain configurational element for .userInfo-config and pages with UHF
     * may have me control disabled.
     */
    public isConfigured(): boolean {
        return this._isConfigured;
    }

    public static getInstance(): UserInfoSingleton {
        if (!UserInfoSingleton._instance) {
            UserInfoSingleton._instance = new UserInfoSingleton();
        }
        return UserInfoSingleton._instance;
    }
}
