
const FirstNameHeaderInterceptor = (config: any) => {
    if(config.FirstName)
    {
        config.headers['FirstName'] = config.FirstName;
    }
    return config;
}

export default FirstNameHeaderInterceptor;
