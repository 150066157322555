import { AxiosCustomConfig } from '../httpClient/HttpClient';
import { TentOptionsHandler } from '../handlers/TentOptionsHandler';
import { TENTED_PAGE_FLAG_HEADER_NAME } from '../../components/Constants';

const TentOptionsHeaderInterceptor = async (config: AxiosCustomConfig) => {

    const isTentedPage = await TentOptionsHandler.isTentedPage();

    if(isTentedPage != null && "true" === isTentedPage)
    {
        config.headers[TENTED_PAGE_FLAG_HEADER_NAME] = isTentedPage;
    }
    return config;
}

export default TentOptionsHeaderInterceptor;
