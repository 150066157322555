import {AxiosRequestConfig} from 'axios';
import {HttpClientBuilder} from './HttpClientBuilder'

/* Exposes a Http client interface with
 *   CompactHeaderName: authentication tokens added to the header
 *   CompactHeaderPrefix: prefix to be added to authentication token in header
 *   MUIDHeader: muid cookie added to the header
 * To use the client, you can export this interface and make restful requests following the syntax from axios library
 */

//AddCV() need to be removed when 1DS configuration is ready in higher environments
export const HttpClient = new HttpClientBuilder()
    .addCV()
    .addAuthentication()
    .addXAuthMUID()
    .addRiskSessionId()
    .addRetry(HttpClientBuilder.RETRIES_COUNT_DEFAULT, HttpClientBuilder.RETRY_TIMEOUT_DEFAULT)
    .addFirstName()
    .addTentOptions()
    .addXAEMLoadTest()
    .addXMSVectorId()
    .addUcsSid()
    .build();

export interface AxiosCustomConfig extends AxiosRequestConfig {
    CompactHeaderName?: string;
    CompactHeaderPrefix?: string;
    AadHeaderPrefix?: string;
    MUIDHeader?: string;
    riskIdAppend?: boolean;
    FirstName?: string;
    isTentedPage?: string;
    xAemLoadTestHeader?: string;
    xMSVectorId?: string;
    cv?: string;
    UcsSidHeader?: string;
}
