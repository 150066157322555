import {CustomWindow} from '../window/CustomWindow';
// cast global var window with CustomWindow
declare let window: CustomWindow;

/* A utility class to retrieve tent options
 */
export class TentOptionsHandler {
    static async isTentedPage(): Promise<string> {
        if (!(window.tentoptions && window.tentoptions.isTentedPage)) { return Promise.resolve(null);}
        return Promise.resolve(window.tentoptions.isTentedPage);
    }

}
